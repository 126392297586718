import React, { useEffect, useState } from "react";
import HeaderInner from "./HeaderInner";
import { useSelector, useDispatch } from "react-redux";
import configureStore from "../../../store/configureStore";
import "./App.css";
import Loader from "../../common/Loader";
import PayrollCard from "./common/PayrollCard";
import SearchField from "../../common/SearchField";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { get_payroll, get_payroll_instance } from "./actions/action-payroll";
import { get_all_staff } from "./actions/action-staff";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { alpha } from "@material-ui/core/styles";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from "../../../reducers"
import Footer from "./Footer";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
  },
}));

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2);
  return [date.getFullYear(), mnth].join("");
}

export default function Payroll() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDate, handleDateChange] = useState(new Date());
  const { payroll, showGlobalLoader } = useSelector((state) => state.payroll);
  const { staffs } = useSelector((state) => state.staffs);
  const { activeOuid } = store.getState().permission;
  const onDateChange = (event) => {
    handleDateChange(event);
    console.log("Date change event", event);
    fetchPayrollData(dispatch, event);
  };

  const fetchStaffData = async (dispatch) => {
    await dispatch(get_all_staff(activeOuid));
  };

  const fetchPayrollData = async (dispatch, selectedDate) => {
    const month = convert(selectedDate);
    await dispatch(get_payroll(activeOuid, month));
  };

  const fetchPayrollInstanceData = async (dispatch, selectedDate) => {
    const month = convert(selectedDate);
    const query = `month=${month}`;
    // await dispatch(get_payroll_instance(activeOuid, query));
  };

  function renderPayrollList(payroll, staffs) {
    console.log("Render Staff List for attendance *********", payroll, staffs);
    console.log("payroll.length", payroll.length);
    for (let payrollindex = 0; payrollindex < payroll.length; payrollindex++) {
      const payrollitem = payroll[payrollindex];
      console.log("payrollitem", payrollitem);
      for (let staffindex = 0; staffindex < staffs.length; staffindex++) {
        const staffitem = staffs[staffindex];
        if (staffitem.data.username === payrollitem.data.username) {
          console.log("Matched only", payrollitem.data.amount);
          staffitem.data["amount"] = payrollitem.data.amount;
          console.log("staffitem", staffitem);
        }
        if (staffitem.data.username !== payrollitem.data.username) {
          console.log("Not matched");
        }
      }
    }
    return (
      <List className={classes.root}>
        {staffs.map((item) => (
          <PayrollCard
            key={item.data.username}
            status={
              item.data.amount ? (
                <div className="payrollCardInfoGreen">
                  {"Net pay: " + item.data.amount + "/-"}
                </div>
              ) : (
                <div className="payrollCardInfoRed">Profile incomplete</div>
              )
            }
            link={item.data.amount ? `payrollprofile` : `payrollsetup`}
            item={item.data}
          />
        ))}
      </List>
    );
  }

  useEffect(() => {
    fetchStaffData(dispatch);
    fetchPayrollData(dispatch, selectedDate);
    fetchPayrollInstanceData(dispatch, selectedDate);
    console.log("selectedDate", convert(selectedDate));
  }, [dispatch]);

  return (
    <div className="">
      <HeaderInner pageTitle="Payroll" onClick={() => {history.push("/")}}/>

      <div className="HrmAppPage container--narrow">
        <div className="w_box mb">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              views={["month", "year"]}
              label="Month"
              helperText=""
              value={selectedDate}
              onChange={onDateChange}
              variant="dialog"
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>
        </div>
        {/* <div className="leaveListPendingCard">
          <div className="leaveListPendingCardHeader">
            <div>Overview</div>
          </div>
          <div className="leaveListPendingCardBody">
            <div>
              <div className="InfoLabel">Employees</div>
              <div className="InfoData">{payroll.length}</div>
            </div>
            <div>
              <div className="InfoLabel"> Payout</div>
              <div className="InfoData">0</div>
            </div>
            <div>
              <div className="InfoLabel">Payslips Sent</div>
              <div className="InfoData">3</div>
            </div>
          </div>
          <div className="leaveListPendingCardBody">
            <div>
              <div className="InfoLabel">Gross Salary</div>
              <div className="InfoData">300000/-</div>
            </div>
            <div>
              <div className="InfoLabel">Net Salary</div>
              <div className="InfoData">25000/-</div>
            </div>
            <div>
              <div className="InfoLabel">Income Tax</div>
              <div className="InfoData">1000/-</div>
            </div>
          </div>
        </div> */}
        {/* <div className="searchWrapper">
          <SearchField
            onChange={(e) => this.search(e.target.value.split(" "), staffs)}
          />
        </div> */}
        <div>
          {showGlobalLoader ? (
            <Loader />
          ) : (
            <>
              {
                renderPayrollList(payroll, staffs)
                // : "No payroll data found..."
              }
            </>
          )}
        </div>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
