import React from "react";
import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Font } from "@react-pdf/renderer";


// Import font files
import RobotoRegular from './fonts/Roboto-Regular.ttf';
import RobotoBold from './fonts/Roboto-Bold.ttf';

// Register the font
Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoBold, fontWeight: 900 },
  ],
});

const styles = StyleSheet.create({
  
  payslipHeadingWrap:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    marginTop: "15px",
    marginBottom:"15px"
  },
  payslipHeading_118:{
    fontSize:"16px",
    fontFamily: 'Roboto',
    fontWeight: 900,
  },
  payslipsubHeading:{
    paddingLeft:"15px",
    textTransform:"capitalize",
    fontSize:"12px",
  },
  payslipsubHeadingBold:{
    fontFamily: 'Roboto',
    fontWeight: 700,
    color:"#000000de",
  },
  tableheadText:{
    textAlign:"center",
    fontFamily:"Roboto",
    fontWeight:600,
    fontSize:"12px",
    color:"#000000de",
    padding:"15px"
  },
  MuiTableRowRoot:{
    backgroundColor: "#dedede",
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    padding:"16px",
    fontSize:"12px",
    borderBottom:"1px solid rgba(224, 224, 224, 1)",
    fontFamily:"Roboto",
    fontWeight:600,
    color: "#000000de"
  },
  MuiTableRowDescription:{
    textAlign:"left"
  },
  MuiTableRowAmount:{
    textAlign:"right",
  },
  MuiTableRowChild:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    padding:"16px",
    fontSize:"12px",
    borderBottom:"1px solid rgba(224, 224, 224, 1)",
    fontFamily:"Roboto",
    fontWeight:400,
    color: "#000000de"
  },
  MuiTableRowChildAmount:{
    textAlign:"right"
  },
  MuiTableRowChildName:{
    textAlign:"left"
  },
  MuiTableRowChildDeduction:{
    textAlign:"left",
    fontWeight:"700"
  },
  MuiTableRowChildDeductionAmount:{
    textAlign:"right",
    fontWeight:"700"
  }
});

const TAX_RATE = 0.07;

function calcTotalDeductions(payroll) {
  let totalDeductions = 0;
  for (let index = 0; index < payroll.deductions.length; index++) {
    let amount = parseFloat(payroll.deductions[index].amount);
    totalDeductions += amount;
  }
  return totalDeductions;
}

function calcTotalEarnings(payroll) {
  let totalEarnings = 0;
  for (let index = 0; index < payroll.earnings.length; index++) {
    let amount = parseFloat(payroll.earnings[index].amount);
    totalEarnings += amount;
  }
  return totalEarnings;
}

const DownloadPayslip = ({ payroll }) => {
  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>
        <View>
          <View style={styles.payslipHeadingWrap}><Text style={styles.payslipHeading_118}>Payslip</Text></View>
          <View><Text style={styles.payslipsubHeading}><b style={styles.payslipsubHeadingBold}>Month:</b> {payroll.month.substring(4, 6) + '/' + payroll.month.substring(0, 4)}</Text></View>
          <View><Text style={styles.payslipsubHeading}><b style={styles.payslipsubHeadingBold}>Employee Name:</b> {payroll.name}</Text></View>
          <View><Text style={styles.payslipsubHeading}><b style={styles.payslipsubHeadingBold}>Company Name:</b> {payroll.companyName}</Text></View>
        </View>
        <View style={styles.table}>
          <View style={styles.tablehead}>
            <Text style={styles.tableheadText} colSpan={4}>Earnings</Text>
          </View>
          <View style={styles.MuiTableRowRoot}>
            <Text style={styles.MuiTableRowDescription}>Description</Text>
            <Text style={styles.MuiTableRowAmount}>Amount</Text>
          </View>
          {payroll.earnings && payroll.earnings.map((row, index) => (
            <View key={index} style={styles.MuiTableRowChild}>
              <Text style={styles.MuiTableRowChildName}>{row.name}</Text>
              <Text style={styles.MuiTableRowChildAmount}>Rs {row.amount}/-</Text>
            </View>
          ))}
          <View style={styles.tablehead}>
            <Text style={styles.tableheadText} colSpan={4}>Deductions</Text>
          </View>
          <View style={styles.MuiTableRowRoot}>
            <Text style={styles.MuiTableRowDescription}>Description</Text>
            <Text style={styles.MuiTableRowAmount}>Amount</Text>
          </View>
          {payroll.deductions && payroll.deductions.map((row, index) => (
            <View key={index} style={styles.MuiTableRowChild}>
              <Text style={styles.MuiTableRowChildName}>{row.name}</Text>
              <Text style={styles.MuiTableRowChildAmount}>Rs {row.amount}/-</Text>
            </View>
          ))}
          <View style={styles.MuiTableRowChild}>
            <Text style={styles.MuiTableRowChildDeduction}>Total Deductions</Text>
            <Text style={styles.MuiTableRowChildAmount}>Rs {calcTotalDeductions(payroll)}/-</Text>
          </View>
          <View style={styles.MuiTableRowChild}>
            <Text style={styles.MuiTableRowChildDeduction}>Total Earnings</Text>
            <Text style={styles.MuiTableRowChildAmount}>Rs {calcTotalEarnings(payroll)}/-</Text>
          </View>
          <View style={styles.MuiTableRowChild}>
            <Text style={styles.MuiTableRowChildDeduction}><b>Net salary</b></Text>
            <Text style={styles.MuiTableRowChildDeductionAmount}><b>Rs {calcTotalEarnings(payroll) - calcTotalDeductions(payroll)}/-</b></Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink document={<MyDocument />} fileName="payslip.pdf">
      <Button
        variant="contained"
        color="primary"
        startIcon={<GetAppIcon />}
        style={{ marginTop: '15px' }}
      >
        Download Payslip
      </Button>
    </PDFDownloadLink>
  );
};

export default DownloadPayslip;
