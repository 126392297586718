import {
  APPLY_LEAVE_REQUESTED,
  APPLY_LEAVE_SUCCESS,
  APPLY_LEAVE_ERRORED,
  GET_ALL_LEAVE_REQUESTED,
  GET_ALL_LEAVE_SUCCESS,
  GET_ALL_LEAVE_ERRORED,
  GET_MY_LEAVE_REQUESTED,
  GET_MY_LEAVE_SUCCESS,
  GET_MY_LEAVE_ERRORED,
  UPDATE_LEAVE_REQUESTED,
  UPDATE_LEAVE_SUCCESS,
  UPDATE_LEAVE_ERRORED,
  GET_LEAVE_CONFIG_REQUESTED,
  GET_LEAVE_CONFIG_SUCCESS,
  GET_LEAVE_CONFIG_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: true,
  leaves: [],
  myleaves: [],
  leaveConfig: [],
};

export function leaves(state = defaultState, { type, payload }) {
  switch (type) {
    case APPLY_LEAVE_REQUESTED:
      console.log("REGISTER_STAFF_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case APPLY_LEAVE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case APPLY_LEAVE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_ALL_LEAVE_REQUESTED:
      // console.log("GET_ALL_STAFF_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        leaves: [],
      };

    case GET_ALL_LEAVE_SUCCESS:
      console.log("GET_ALL_STAFF_SUCCESS : ", JSON.stringify(payload));
      return {
        ...state,
        showGlobalLoader: false,
        leaves: payload,
      };

    case GET_ALL_LEAVE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_MY_LEAVE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        myleaves: [],
      };

    case GET_MY_LEAVE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        myleaves: payload,
      };

    case GET_MY_LEAVE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_LEAVE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        // leaves: [],
      };

    case UPDATE_LEAVE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        // leaves: payload.data,
      };

    case UPDATE_LEAVE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_LEAVE_CONFIG_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        leaveConfig: [],
      };

    case GET_LEAVE_CONFIG_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        leaveConfig: payload,
      };

    case GET_LEAVE_CONFIG_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
