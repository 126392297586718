import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Avatar, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import configureStore from "../../../store/configureStore";
import "./App.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { history } from "../../../reducers";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {
  create_payroll_profile,
  create_payroll_instance,
} from "./actions/action-payroll";
import { get_staff_details } from "./actions/action-staff";
import avatar from "./images/Frame.svg";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Footer from "./Footer";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  radioButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0",
  },
  radioGroup: {
    width: "100%",
  },
  input: {
    marginBottom: 10,
  },
  avatar: {
    textTransform: "uppercase",
    marginRight: 8,
  },
}));
// const handleChange = (event) => {
//     setAge(event.target.value);
//   };
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2);
  return [date.getFullYear(), mnth].join("");
}

export default function PayrollSetup(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const { activeOuid } = store.getState().permission;

  let username;
  const initialStateNewComponent = { name: "", amount: "" };
  const [newComponent, setNewComponent] = useState(initialStateNewComponent);

  const [state, setState] = useState({
    username: "",
    amountType: "Monthly",
    amount: 0,
    amountCurrency: "INR",
    name: "",
    month: "",
    earnings: [
      {
        name: "Basic",
        amount: "0",
      },
      {
        name: "HRA",
        amount: "0",
      },
    ],
    deductions: [
      {
        name: "Income Tax",
        amount: "0",
      },
      {
        name: "Professional Tax",
        amount: "0",
      },
    ],
  });

  const handleNetPayChange = (event) => {
    console.log("[event.target.id]", event.target.id);
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleNewComponentChange = (event) => {
    console.log("[event.target.id]", [event.target.id]);
    setNewComponent({ ...newComponent, [event.target.id]: event.target.value });

    console.log("state", newComponent);
  };

  const handleEarningsChange = (event) => {
    // setState({ ...state, earnings: [ ...state.earnings, {[event.target.id]: event.target.value }]});
    // setState( state => [...state.earnings, {[event.target.id]: event.target.value }]);
    // setState({ ...state, earnings: { ...state.earnings, [event.target.id]: event.target.value }});
    // setState( {...state, earnings: newList});
    state.earnings.map((item) => {
      console.log("item.name", item.name, [event.target.id]);
      if (item.name === event.target.id) {
        console.log("Matched");
        item.amount = event.target.value;
      } else {
        console.log("Not Matched");
      }
    });
    console.log("state", state);
  };

  const handleDeductionsChange = (event) => {
    // setState({ ...state, deductions: [ ...state.deductions, {[event.target.id]: event.target.value }]});
    // setState({ ...state, deductions: { ...state.deductions, [event.target.id]: event.target.value }});
    state.deductions.map((item) => {
      console.log("item.name", item.name, [event.target.id]);
      if (item.name === event.target.id) {
        console.log("Matched");
        item.amount = event.target.value;
      } else {
        console.log("Not Matched");
      }
    });
    console.log("state", state);
  };

  const handleSubmit = (state) => {
    console.log("handleSubmit", state);
    dispatch(create_payroll_profile(state, activeOuid));
    dispatch(create_payroll_instance(state, activeOuid));
    history.push(`/${activeOuid}/payroll`);
  };

  const { staffdetails, showGlobalLoader } = useSelector(
    (state) => state.staffs
  );
  function handleAddEarnings() {
    const newList = state.earnings.concat(newComponent);
    console.log("newList", newList);
    console.log("state.earnings", state.earnings);
    setState({ ...state, earnings: newList });
    setNewComponent(initialStateNewComponent);
  }
  function handleAddDeductions() {
    const newList = state.deductions.concat(newComponent);
    console.log("newList", newList);
    console.log("state.earnings", state.deductions);
    setState({ ...state, deductions: newList });
    setNewComponent(initialStateNewComponent);
  }

  function renderEarningsInput() {
    return state.earnings.map((item) => (
      <TextField
        key={item.name}
        type="number"
        fullWidth
        id={item.name}
        className={classes.input}
        label={item.name}
        variant="outlined"
        defaultValue={item.amount}
        onChange={handleEarningsChange}
      />
    ));
  }
  function renderDeductionsInput() {
    return state.deductions.map((item) => (
      <TextField
        key={item.name}
        type="number"
        fullWidth
        id={item.name}
        className={classes.input}
        label={item.name}
        variant="outlined"
        defaultValue={item.amount}
        onChange={handleDeductionsChange}
      />
    ));
  }

  useEffect(() => {
    username = window.location.pathname.split("/");
    username = username[3];
    console.log("username", username);
    // setState({...state, username: username});
    const fetchData = async (dispatch) => {
      await dispatch(get_staff_details(activeOuid, username));
      const converted = convert(date);
      console.log(
        "staffdetails.name",
        store.getState().staffs.staffdetails.name
      );
      setState({
        ...state,
        month: converted,
        username: username,
        name: store.getState().staffs.staffdetails.name,
      });
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Payroll Setup" />
      <div className="container--narrow HrmAppPage">
        <div className="HrmAppHeaderProfileDetails">
          {/* <img src={avatar} /> */}
          <Avatar
            className={classes.avatar}
            style={{
              backgroundColor: "#214b87",
            }}
          >
            {staffdetails.username && staffdetails.username.charAt(0)}
          </Avatar>
          <div>
            <div className="HrmAppHeaderProfileName">{staffdetails.name}</div>
            <div className="HrmAppHeaderProfileDesignation">
              {staffdetails.username}
            </div>
          </div>
        </div>
        <form>
          <TextField
            fullWidth
            id="amount"
            type="number"
            className={classes.input}
            label="Net Salary"
            variant="outlined"
            onChange={handleNetPayChange}
          />
          <h5>Earnings</h5>
          {renderEarningsInput()}
          <div className="addPayRollComponent">
            <div>
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                size="small"
                className={classes.input}
                onChange={handleNewComponentChange}
              />
            </div>
            <div>
              <TextField
                id="amount"
                label="Amount"
                type="number"
                variant="outlined"
                size="small"
                className={classes.input}
                onChange={handleNewComponentChange}
              />
            </div>
            <div>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="primary"
                aria-label="menu"
                onClick={() => handleAddEarnings(state.earnings)}
              >
                <AddBoxIcon />
              </IconButton>
            </div>
          </div>
          <h5>Deductions</h5>
          {renderDeductionsInput()}
          <div className="addPayRollComponent">
            <div>
              <TextField
                id="name"
                label="Component"
                variant="outlined"
                size="small"
                className={classes.input}
                onChange={handleNewComponentChange}
              />
            </div>
            <div>
              <TextField
                id="amount"
                label="Amount"
                type="number"
                variant="outlined"
                size="small"
                className={classes.input}
                onChange={handleNewComponentChange}
              />
            </div>
            <div>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="primary"
                aria-label="menu"
                onClick={() => handleAddDeductions(state.deductions)}
              >
                <AddBoxIcon />
              </IconButton>
            </div>
          </div>
        </form>
        <div className="HrmAppHomePageButtonWrapper">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(state)}
          >
            Save
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
