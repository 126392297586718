import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import StaffCard from "./common/StaffCard";
import CommonSnackBar from "../../common/CommonSnackBar";
import "./App.css";

import { get_all_staff } from "./actions/action-staff";

import Loader from "../../common/Loader";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import List from "@material-ui/core/List";
import { history } from "../../../reducers";
import Footer from "./Footer";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function renderStaffList(staffs, classes) {
  return (
    <List className={classes.root}>
      {staffs.map((item) => (
        <StaffCard key={item.data.username} item={item.data} />
      ))}
    </List>
  );
}

export default function Staff() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { staffs, showGlobalLoader } = useSelector((state) => state.staffs);
  const { activeOuid } = store.getState().permission;

  useEffect(() => {
    const fetchData = async (dispatch) => {
      await dispatch(get_all_staff(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <>
      <HeaderInner pageTitle="Employees" onClick={() => {history.push("/")}}/>
      <div className="HrmAppPage container--narrow">
        <div className="">
          {showGlobalLoader ? (
            <Loader />
          ) : (
            <>
              <h1>{staffs.length} Employees</h1>
              {staffs.length !== 0
                ? renderStaffList(staffs, classes)
                : "No staffs found..."}
            </>
          )}
          <div className="HrmAppHomePageButtonWrapper">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => history.push(`/${activeOuid}/addstaff`)}
            >
              Add Employees
            </Button>
          </div>
        </div>
        <Footer />
        <CommonSnackBar />
      </div>
    </>
  );
}
