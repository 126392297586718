import {
  REGISTER_STAFF_REQUESTED,
  REGISTER_STAFF_SUCCESS,
  REGISTER_STAFF_ERRORED,
  GET_ALL_STAFF_REQUESTED,
  GET_ALL_STAFF_SUCCESS,
  GET_ALL_STAFF_ERRORED,
  GET_STAFF_DETAILS_REQUESTED,
  GET_STAFF_DETAILS_SUCCESS,
  GET_STAFF_DETAILS_ERRORED,
  UPDATE_STAFF_REQUESTED,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_ERRORED,
  DELETE_STAFF_REQUESTED,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: true,
  staffs: [],
  staffdetails: {},
};

export function staffs(state = defaultState, { type, payload }) {
  switch (type) {
    case REGISTER_STAFF_REQUESTED:
      console.log("REGISTER_STAFF_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case REGISTER_STAFF_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case REGISTER_STAFF_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_ALL_STAFF_REQUESTED:
      // console.log("GET_ALL_STAFF_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        staffs: [],
      };

    case GET_ALL_STAFF_SUCCESS:
      // console.log("GET_ALL_STAFF_SUCCESS : ", JSON.stringify(payload));
      return {
        ...state,
        showGlobalLoader: false,
        staffs: payload.items,
      };

    case GET_ALL_STAFF_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_STAFF_DETAILS_REQUESTED:
      // console.log("GET_STAFF_DETAILS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        staffdetails: {},
      };

    case GET_STAFF_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        staffdetails: payload.data,
      };

    case GET_STAFF_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_STAFF_REQUESTED:
      // console.log("UPDATE_STAFF_DETAILS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_STAFF_SUCCESS:
      // console.log("UPDATE_STAFF_DETAILS_SUCCESS : ", JSON.stringify(payload));
      return {
        ...state,
        showGlobalLoader: false,
      };

    case UPDATE_STAFF_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case DELETE_STAFF_REQUESTED:
      // console.log("DELETE_STAFF_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_STAFF_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_STAFF_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    default:
      return state;
  }
}
