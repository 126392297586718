import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Avatar, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import configureStore from "../../../store/configureStore";
import "./App.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { history } from "../../../reducers";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {
  get_payroll_profile,
  update_payroll_profile,
} from "./actions/action-payroll";
import { get_staff_details } from "./actions/action-staff";
import avatar from "./images/Frame.svg";
import Loader from "../../common/Loader";
import Footer from "./Footer";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  radioButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0",
  },
  radioGroup: {
    width: "100%",
  },
  input: {
    marginBottom: 10,
  },
  avatar: {
    textTransform: "uppercase",
    marginRight: 8,
  },
}));

export default function PayrollSetupEdit(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { activeOuid } = store.getState().permission;

  let username;

  const { payrollprofile, showGlobalLoader } = useSelector(
    (state) => state.payroll
  );

  const [state, setState] = React.useState(payrollprofile);

  // const radioHandler = (status) => {
  //   console.log("Status", status);
  //   setStatus(status);
  //   setState({ ...state, amountType: status });
  // };

  const handleChange = (event) => {
    console.log("[event.target.id]", [event.target.id]);
    console.log("state", state);
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleEarningsChange = (event) => {
    console.log("[event.target.id]", [event.target.id]);
    setState({
      ...state,
      earnings: [...state.earnings, { [event.target.id]: event.target.value }],
    });
    // setState( state => [...state.earnings, {[event.target.id]: event.target.value }]);
    console.log("state", state);
  };

  const handleDeductionsChange = (event) => {
    console.log("Deduction change", [event.target.id]);
    console.log("key", state.deductions);
    if (!("key" in state.deductions)) {
      console.log("true", true);
    } // true if "key" doesn't exist in object
    if (!"key" in state.deductions) {
      console.log("false", false);
    }
    // ERROR!  Equivalent to "false in obj"
    setState({
      ...state,
      deductions: [
        ...state.deductions,
        { [event.target.id]: event.target.value },
      ],
    });

    // setState({ ...state, deductions: { ...state.deductions, [event.target.id]: event.target.value }});
  };

  function renderEarningsInput(earnings){
    console.log("earnings", earnings);
    earnings.map((item) => (
      console.log("item", item)
    ));
  }
  const handleSubmit = (state) => {
    console.log("handleSubmit", state.identifier);
    dispatch(update_payroll_profile(state, activeOuid, state.identifier));
    history.push(`/${activeOuid}/payroll`);
  };

  const { staffdetails } = useSelector((state) => state.staffs);

  useEffect(() => {
    username = window.location.pathname.split("/");
    username = username[3];
    console.log("name", username);

    // setState({ ...state, username: username });
    const fetchData = async (dispatch) => {
      await dispatch(get_staff_details(activeOuid, username));
      await dispatch(get_payroll_profile(activeOuid, username));
    };
    fetchData(dispatch);
  }, [dispatch]);
  return (
    <div>
      <HeaderInner pageTitle="Payroll Setup" />
      <div className="container--narrow HrmAppPage">
        {/* <FormControl component="fieldset" className={classes.radioButton}>
                    <RadioGroup aria-label="position" className={classes.radioGroup}  name="position" defaultValue="top" >
                        <FormControlLabel value="Monthly" control={<Radio checked={ status === "Monthly" } onClick={() => radioHandler("Monthly")} color="primary" />} label="Monthly" />
                        {status === "Monthly" && 
                            <TextField
                                fullWidth
                                id="amount"
                                label={status}
                                variant="outlined"
                                onChange={handleAmountChange}
                            />
                        }
                        <FormControlLabel value="Daily" control={<Radio checked={ status === "Daily" } onClick={() => radioHandler("Daily")} color="primary" />} label="Daily" />
                        {status === "Daily" && 
                        <TextField
                            fullWidth
                            id="amount"
                            label={status}
                            variant="outlined"
                            onChange={handleAmountChange}
                        />}
                        <FormControlLabel value="Hourly" control={<Radio checked={ status === "Hourly" } onClick={() => radioHandler("Hourly")} color="primary" />} label="Hourly" />
                        {status === "Hourly" && 
                        <TextField
                            fullWidth
                            id="amount"
                            label={status}
                            variant="outlined"
                            onChange={handleAmountChange}
                        />}
                    </RadioGroup>
                </FormControl> */}

        <div className=" HrmAppHeaderProfileDetails">
          {/* <img src={avatar} /> */}
          <Avatar
            className={classes.avatar}
            style={{
              backgroundColor: "#214b87",
            }}
          >
            {staffdetails.name && staffdetails.name.charAt(0)}
          </Avatar>
          <div>
            <div className="HrmAppHeaderProfileName">{staffdetails.name}</div>
            <div className="HrmAppHeaderProfileDesignation">
              {staffdetails.username}
            </div>
          </div>
        </div>
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <form className="w_box">
            <TextField
              fullWidth
              id="amount"
              type="number"
              className={classes.input}
              label="Net Salary"
              variant="outlined"
              onChange={handleChange}
              defaultValue={state.amount}
            />
            <h5>Earnings</h5>
            {/* {
              state.earnings && renderEarningsInput(state.earnings)
            } */}
            <TextField
              fullWidth
              id="basic"
              type="number"
              className={classes.input}
              label="Basic"
              variant="outlined"
              onChange={handleEarningsChange}
            />
            <TextField
              fullWidth
              id="hra"
              type="number"
              className={classes.input}
              label="HRA"
              variant="outlined"
              onChange={handleEarningsChange}
            />
            <h5>Deductions</h5>
            <TextField
              fullWidth
              id="incomeTax"
              type="number"
              className={classes.input}
              label="Income Tax"
              variant="outlined"
              onChange={handleDeductionsChange}
            />
            <TextField
              fullWidth
              id="professionalTax"
              type="number"
              className={classes.input}
              label="Professional Tax"
              variant="outlined"
              onChange={handleDeductionsChange}
            />
          </form>
        )}
        <div className="HrmAppHomePageButtonWrapper">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(state)}
          >
            Save
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
