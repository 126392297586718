import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import DateFnsUtils from "@date-io/date-fns";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { Avatar, IconButton } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import { jsPDF } from "jspdf";
import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import Loader from "../../common/Loader";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import SendIcon from "@material-ui/icons/Send";
import EditIcon from "@material-ui/icons/Edit";
import downloadImage from "./images/downloadpdfimage.png";
import avatar from "./images/Frame.svg";
import { history } from "../../../reducers";
import SettingsIcon from "@material-ui/icons/Settings";
import configureStore from "../../../store/configureStore";
import "./App.css";
import {
  get_payroll_instance,
  get_payroll_profile,
  update_payroll_instance,
} from "./actions/action-payroll";
import PayrollSetup from "./PayrollSetup";
import { payroll } from "./reducers/reducer-payroll";
import Payslip from "./common/Payslip";
import Footer from "./Footer";
import PaySlipNew from "./common/PaySlipNew";

const store = configureStore();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
  },
  attendanceButton: {
    justifyContent: "flex-end",
    display: "flex",
  },
  tabPanel: {
    background: "#fff",
  },
  tabHead: {
    boxShadow: "none",
    borderTop: "1px solid #eee",
  },
  tabs: {
    background: "#fff",
    boxShadow: "none",
  },
  tab: {
    borderBottom: "1.98502px solid #4B4DED",
  },
  avatar: {
    textTransform: "uppercase",
    marginRight: 8,
  },
}));
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2);
  return [date.getFullYear(), mnth].join("");
}

export default function PayrollProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const componentRef = useRef();

  const { policies } = store.getState().permission;
  var usertype = policies[0].split("=");
  usertype = usertype[1];
  console.log("permissions", usertype);

  let userProfile;
  userProfile = window.location.pathname.split("/");
  userProfile = userProfile[3];

  const doc = new jsPDF();

  const initialPayrollProfile = {};

  const { payrollprofile } = useSelector((state) => state.payroll);
  const { payrollinstanceprofile, showGlobalLoader } = useSelector(
    (state) => state.payroll
  );

  const initialStateNewComponent = { name: "", amount: "" };
  const [newComponent, setNewComponent] = React.useState(
    initialStateNewComponent
  );

  const [state, setState] = React.useState(initialPayrollProfile);
  const [payrollinstanceState, setPayrollinstanceState] = React.useState(
    initialPayrollProfile
  );

  const { activeOuid } = store.getState().permission;

  const [value, setValue] = React.useState(0);

  const theme = useTheme();

  const [selectedDate, handleDateChange] = useState(new Date());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onDateChange = (event) => {
    handleDateChange(event);
    console.log("Date change event", event);
    fetchPayroll(dispatch, event);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const fetchData = async (dispatch, userProfile) => {
    await dispatch(get_payroll_profile(activeOuid, userProfile));
    setState(store.getState().payroll.payrollprofile);
  };

  const fetchPayroll = async (dispatch, date) => {
    const month = convert(date);
    const query = `month=${month}&username=${userProfile}`;
    console.log("query", query);
    await dispatch(get_payroll_instance(activeOuid, query));
    setPayrollinstanceState(store.getState().payroll.payrollinstanceprofile);
  };

  const handleDownload = () => {
    doc.text("Hello world!", 10, 10);
    doc.save(`payslip${selectedDate}.pdf`);
  };

  const handleSavePayrollInstance = (payrollinstanceState) => {
    console.log("handleSubmit", payrollinstanceState);
    dispatch(
      update_payroll_instance(
        payrollinstanceState,
        activeOuid,
        payrollinstanceState.identifier
      )
    );
  };

  console.log("state", state);
  console.log("payrollinstanceState", payrollinstanceState);
  function renderEarningsComponents(earnings) {
    return (
      <>
        {earnings.map((item) => (
          <div className="payRollComponent" key={item.name}>
            <div className="payRollComponentName">{item.name}</div>
            <div className="payRollComponentValue">{item.amount}</div>
            {/* <IconButton
              edge="start"
              className={classes.menuButton}
              color="primary"
              aria-label="menu"
              // onClick={() => history.push(`/edwin/attendancesetup`)}
            >
            { usertype === 'admin' && <EditIcon /> }
            </IconButton> */}
          </div>
        ))}
      </>
    );
  }

  function renderDeductionsComponents(deductions) {
    return (
      <>
        {deductions.map((item) => (
          <div className="payRollComponent" key={item.name}>
            <div className="payRollComponentName">{item.name}</div>
            <div className="payRollComponentValue">{item.amount}</div>
            {/* <IconButton
              edge="start"
              className={classes.menuButton}
              color="primary"
              aria-label="menu"
              // onClick={() => history.push(`/edwin/attendancesetup`)}
            >
            { usertype === 'admin' && <EditIcon /> }
            </IconButton> */}
          </div>
        ))}
      </>
    );
  }
  function handleAddEarnings() {
    const newList = payrollinstanceState.earnings.concat(newComponent);
    console.log("newList", newList);
    setPayrollinstanceState({ ...payrollinstanceState, earnings: newList });
    setNewComponent(initialStateNewComponent);
  }
  function handleAddDeductions() {
    const newList = payrollinstanceState.deductions.concat(newComponent);
    setPayrollinstanceState({ ...payrollinstanceState, deductions: newList });
    setNewComponent(initialStateNewComponent);
  }

  const handleNewComponentChange = (event) => {
    setNewComponent({ ...newComponent, [event.target.id]: event.target.value });
  };

  useEffect(() => {
    fetchData(dispatch, userProfile);
    fetchPayroll(dispatch, selectedDate);
  }, [dispatch]);

  return (
    // <>Payroll profile</>
    <div className="pageWrapper">
      <HeaderInner pageTitle="Payroll Profile" />

      <div className="HrmAppPage container--narrow">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <>
            <div>
              <div className="payrollProfileCard">
                <div className="payrollProfileCardHeader">
                  <div className="leaveListPendingCardProfile">
                    {/* <img src={avatar} /> */}
                    <Avatar
                      className={classes.avatar}
                      style={{
                        backgroundColor: "#214b87",
                      }}
                    >
                      {payrollprofile.name && payrollprofile.name.charAt(0)}
                    </Avatar>
                    <div>
                      {payrollprofile.name && (
                        <div className="HrmAppHeaderProfileName">
                          {payrollprofile.name}
                        </div>
                      )}
                      <div className="HrmAppHeaderProfileDesignation">
                        {payrollprofile.username}
                      </div>
                    </div>
                  </div>
                  { usertype === 'admin' && 
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="default"
                    aria-label="menu"
                    onClick={() =>
                      history.push(
                        `/${activeOuid}/payrollsetupedit/${payrollprofile.username}`
                      )
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                  }
                </div>
                <div className="payrollProfileCardBody">
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        views={["month", "year"]}
                        label="Month"
                        helperText=""
                        value={selectedDate}
                        onChange={onDateChange}
                        variant="dialog"
                        inputVariant="outlined"
                        maxDate={new Date()}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="leaveListPendingCardLeavetype">
                    <div>Net pay</div>
                    <div>{payrollinstanceprofile.amount} /-</div>
                  </div>
                </div>
              </div>
            </div>
            {payrollinstanceprofile.length != 0 ? (
              <>
                <AppBar position="static" color="secondary">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    className={classes.tabs}
                  >
                    <Tab
                      className={classes.tab}
                      label="Earnings"
                      {...a11yProps(0)}
                    />
                    <Tab
                      className={classes.tab}
                      label="Deductions"
                      {...a11yProps(1)}
                    />
                    <Tab
                      className={classes.tab}
                      label="Pay slip"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel
                    value={value}
                    index={0}
                    dir={theme.direction}
                    className={classes.tabPanel}
                  >
                    { usertype === 'admin' && 
                    <div className="addPayRollComponent">
                      <div>
                        <TextField
                          id="name"
                          label="Name"
                          variant="outlined"
                          size="small"
                          className={classes.input}
                          onChange={handleNewComponentChange}
                        />
                      </div>
                      <div>
                        <TextField
                          id="amount"
                          type="number"
                          label="Amount"
                          variant="outlined"
                          size="small"
                          className={classes.input}
                          onChange={handleNewComponentChange}
                        />
                      </div>
                      <div>
                        <IconButton
                          edge="start"
                          className={classes.menuButton}
                          color="primary"
                          aria-label="menu"
                          onClick={() =>
                            handleAddEarnings(payrollinstanceState.earnings)
                          }
                        >
                          <AddBoxIcon />
                        </IconButton>
                      </div>
                    </div>
                    }
                    {payrollinstanceState.earnings &&
                      renderEarningsComponents(payrollinstanceState.earnings)}
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={1}
                    dir={theme.direction}
                    className={classes.tabPanel}
                  >
                    { usertype === 'admin' && 
                    <div className="addPayRollComponent">
                      <div>
                        <TextField
                          id="name"
                          label="Name"
                          variant="outlined"
                          size="small"
                          className={classes.input}
                          onChange={handleNewComponentChange}
                        />
                      </div>
                      <div>
                        <TextField
                          id="amount"
                          type="number"
                          label="Amount"
                          variant="outlined"
                          size="small"
                          className={classes.input}
                          onChange={handleNewComponentChange}
                        />
                      </div>
                      <div>
                        <IconButton
                          edge="start"
                          className={classes.menuButton}
                          color="primary"
                          aria-label="menu"
                          onClick={() =>
                            handleAddDeductions(payrollinstanceState.earnings)
                          }
                        >
                          <AddBoxIcon />
                        </IconButton>
                      </div>
                    </div>
                    }
                    {payrollinstanceState.deductions &&
                      renderDeductionsComponents(
                        payrollinstanceState.deductions
                      )}
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={theme.direction}>
                    {/* <Payslip payroll={payrollinstanceState} /> */}
                    <PaySlipNew payroll={payrollinstanceState}/>
                  </TabPanel>
                </SwipeableViews>
                { usertype === 'admin' && 
                <div className="HrmAppHomePageButtonWrapper">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleSavePayrollInstance(payrollinstanceState)
                    }
                  >
                    Save
                  </Button>
                </div>
                }
              </>
            ) : (
              <>No profile for the month</>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
