import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { TextField } from "@material-ui/core";
import HeaderInner from "./HeaderInner";
import DatePicker from "sassy-datepicker";
import { get_my_attendance } from "./actions/action-attendance";
import configureStore from "../../../store/configureStore";
import Loader from "../../common/Loader";
import { ClassRounded } from "@material-ui/icons";
import Footer from "./Footer";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  attendancelogItemWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  attendancelogItem: {
    width: "50%",
    padding: "5px 0",
  },
}));

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("");
}

export default function AttendanceLog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const { activeOuid } = store.getState().permission;
  const { myAttendance, showGlobalLoader } = useSelector(
    (state) => state.attendance
  );
  let username;

  const convertFrom24To12Format = (time24) => {
    console.log("time24", time24);
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? "AM" : "PM";
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  };

  function addStr(str, index, stringToAdd) {
    return (
      str.substring(0, index) + stringToAdd + str.substring(index, str.length)
    );
  }
  // const [date, setDate] = React.useState("April 2022");

  const handleChange = (event) => {
    setDate(event.target.value);
  };

  // const [view, setView] = React.useState("View All");

  // const handleChangeView = (event) => {
  //   setView(event.target.value);
  // };
  const onChange = (newDate) => {
    username = window.location.pathname.split("/");
    username = username[3];
    setDate(newDate);
    const converted = convert(newDate);
    const queryOnchange = `username=${username}&date=${converted}`;
    console.log("queryOnchange", queryOnchange);
    fetchData(dispatch, queryOnchange);
  };

  function renderAttendanceLog(attendance) {
    console.log("attendance", attendance);
    console.log("render Attendance log *********", attendance[0].data.logs);
    if (!attendance || !attendance[0] || !attendance[0].data.logs) {
      return <div>No logs available</div>;
    }
    
    return (
      <div className={classes.attendancelogItemWrapper}>
        {attendance[0].data.logs.map((item, index)=> (
          <div key={item} className={classes.attendancelogItem}>
            <div className="InfoLabel">{item.type} Time</div>
            <div className="InfoData">
              {convertFrom24To12Format(addStr(item.time, 2, ":"))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  const fetchData = async (dispatch, query) => {
    await dispatch(get_my_attendance(activeOuid, query));
  };

  useEffect(() => {
    username = window.location.pathname.split("/");
    username = username[3];
    console.log("name", username);
    const today =  convert(date);
    const query = `username=${username}&date=${today}`;

    fetchData(dispatch, query);
  }, [dispatch,date]);

  return (
    <>
      {props.header && <HeaderInner pageTitle="Attendance Log" />}
      <div className="HrmAppPage container--narrow">
        <DatePicker onChange={onChange} selected={date} />
        <div className="leaveListPendingCard">
          <div className="leaveListPendingCardHeader">
            <div>Date</div>
          </div>
          {showGlobalLoader ? (
            <Loader />
          ) : (
            <>
              {myAttendance && myAttendance.length !== 0
                ? renderAttendanceLog(myAttendance)
                : "No attendance"}
            </>
          )}
        </div>
        {props.footer && <Footer />}
      </div>
    </>
  );
}
