import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Home from "./Home";
import Leave from "./Leave";
import Staff from "./Staff";
import Attendance from "./Attendance";
import AttendanceSetup from "./AttendanceSetup";
import Payroll from "./Payroll";
import PayrollSetup from "./PayrollSetup";
import AddStaff from "./AddStaff";
import LeaveSetup from "./LeaveSetup";
import LeaveUser from "./LeaveUser";
import ApplyLeave from "./ApplyLeave";
import AttendanceLog from "./AttendanceLog";
import PayrollProfile from "./PayrollProfile";
import PayrollSetupEdit from "./PayrollSetupEdit";
import EditStaff from "./EditStaff";
import StaffProfile from "./StaffProfile";

const store = configureStore();

class HrmApp extends Component {
  render() {
    return (
      <div>
        {/* <Sidebar auth={this.props.auth} permissions={permissions} /> */}
        <Switch>
          <Route exact path="/" render={(props) => <Home />} />
          <Route exact path="/:id/staff" render={(props) => <Staff />} />
          <Route
            exact
            path="/:id/staff-profile/:id"
            render={(props) => <StaffProfile />}
          />

          <Route
            exact
            path="/:id/attendance"
            render={(props) => <Attendance />}
          />
          <Route
            exact
            path="/:id/attendancelog/:id"
            render={(props) => <AttendanceLog header={true} footer={true} />}
          />
          <Route
            exact
            path="/:id/attendancesetup"
            render={(props) => <AttendanceSetup />}
          />
          <Route exact path="/:id/leave" render={(props) => <Leave />} />
          <Route
            exact
            path="/:id/leavesetup"
            render={(props) => <LeaveSetup />}
          />
          <Route
            exact
            path="/:id/leaveuser"
            render={(props) => <LeaveUser />}
          />
          <Route exact path="/:id/payroll" render={(props) => <Payroll />} />
          <Route
            exact
            path="/:id/payrollsetup/:id"
            render={(props) => <PayrollSetup />}
          />
          <Route
            exact
            path="/:id/payrollsetupedit/:id"
            render={(props) => <PayrollSetupEdit />}
          />
          <Route
            exact
            path="/:id/payrollprofile/:id"
            render={(props) => <PayrollProfile />}
          />
          <Route exact path="/:id/addstaff" render={(props) => <AddStaff />} />
          <Route
            exact
            path="/:id/editstaff/:id"
            render={(props) => <EditStaff />}
          />
          <Route
            exact
            path="/:id/applyleave"
            render={(props) => <ApplyLeave />}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HrmApp);
