import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import configureStore from "../../../../store/configureStore";
import DownloadPayslip from './DownloadPayslip';

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxWidth: 700,
    margin: "0 auto",
  },
  payslipHeading: {
    textAlign: "center",
    marginTop: 15,
  },
  payslipDes: {
    textTransform: "capitalize",
    paddingLeft: 15,
  },
  tableHeading: {
    background: "#dedede",
  },
  buttonRed: {
    marginTop: 15,
  },
}));

const TAX_RATE = 0.07;

function calcTotalDeductions(payroll) {
  let totalDeductions = 0;
  for (let index = 0; index < payroll.deductions.length; index++) {
    const amount = parseFloat(payroll.deductions[index].amount);
    totalDeductions += amount;
  }
  return totalDeductions;
}

function calcTotalEarnings(payroll) {
  let totalEarnings = 0;
  for (let index = 0; index < payroll.earnings.length; index++) {
    const amount = parseFloat(payroll.earnings[index].amount);
    totalEarnings += amount;
  }
  return totalEarnings;
}

export default function PaySlipNew(props) {
  const { payroll } = props;
  const { details } = store.getState().myProfile;
  const classes = useStyles();
  const pdfRef = useRef(null);
  console.log("details",details);
  return (
    <>
      <TableContainer
        component={Paper}
        id="payslipTable"
        className={classes.tableContainer}
        ref={pdfRef}
      >
        <h1 className={classes.payslipHeading}>Payslip</h1>
        <div className={classes.payslipDes}>
          <b>Month: </b>
          {payroll.month.substring(4, 6) + '/' + payroll.month.substring(0, 4)}
        </div>
        <div className={classes.payslipDes}>
          <b>Employee Name: </b>
          {payroll.name}
        </div>
        <div className={classes.payslipDes}>
          <b>Company Name: </b>
          {details.data.tenant}
        </div>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={4} className="bold">
                Earnings
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableHeading}>
              <TableCell className="bold">Description</TableCell>
              <TableCell className="bold" align="right">
                Amount
              </TableCell>
            </TableRow>
            
            {payroll.earnings && payroll.earnings.map((row) => (
              <TableRow key={row.name}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">Rs {row.amount}/-</TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell align="center" colSpan={4} className="bold">
                Deductions
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableHeading}>
              <TableCell className="bold">Description</TableCell>
              <TableCell className="bold" align="right">
                Amount
              </TableCell>
            </TableRow>
            { payroll.deductions && payroll.deductions.map((row) => (
              <TableRow key={row.name}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">Rs {row.amount}/-</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell className="bold">Total Deductions</TableCell>
              <TableCell align="right">
                Rs {payroll.deductions && payroll.deductions.length > 0 && calcTotalDeductions(payroll)}/-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="bold">Total Earnings</TableCell>
              <TableCell align="right">
                Rs {payroll.earnings && payroll.earnings.length > 0 && calcTotalEarnings(payroll)}/-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="bold">Net salary</TableCell>
              <TableCell align="right" className="bold">
                Rs {payroll && calcTotalEarnings(payroll) - calcTotalDeductions(payroll)}/-
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className="payslipDownloadContainer">
        <DownloadPayslip payroll={payroll} />
      </div>
    </>
  );
}
