import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import HeaderInner from "./HeaderInner";
import "./App.css";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Avatar, Button } from "@material-ui/core";
import { history } from "../../../reducers";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import { List } from "@material-ui/core";
import avatar from "./images/Frame.svg";
import LeaveCard from "./common/LeaveCard";
import { get_all_leave } from "./actions/action-leave";
import Loader from "../../common/Loader";
import Footer from "./Footer";
import CommonSnackBar from "../../common/CommonSnackBar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
  },
  attendanceButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  avatar: {
    textTransform: "uppercase",
    marginRight: 8,
  },
}));

const store = configureStore();

function cardHandleClick(event) {
  console.log(event);
}

export default function Leave() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { activeOuid } = store.getState().permission;
  const { details } = store.getState().myProfile;
  const [value, setValue] = React.useState(0);

  const { policies } = store.getState().permission;
  var usertype = policies[0].split("=");
  usertype = usertype[1];
  console.log("permissions", usertype);

  const { leaves, showGlobalLoader } = useSelector((state) => state.leaves);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function getDifferenceInDays(startDate, endDate) {
    console.log("startDate", startDate);
    console.log("endDate", endDate);

    const [yearSt, monthSt, daySt] = startDate.split("-");
    const sDate = new Date(yearSt, monthSt - 1, daySt);

    const [yearEnd, monthEnd, dayEnd] = endDate.split("-");
    const eDate = new Date(yearEnd, monthEnd - 1, dayEnd);

    return getBusinessDatesCount(sDate, eDate);
  }

  const getBusinessDatesCount = (startDate, endDate) => {
    let count = 0;
    let curDate = +startDate;
    while (curDate <= +endDate) {
      const dayOfWeek = new Date(curDate).getDay();
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
      if (!isWeekend) {
        count++;
      }
      curDate = curDate + 24 * 60 * 60 * 1000;
    }
    return count;
  };

  function renderPendingLeaveList(leaves) {
    console.log("render leave list*********", leaves);
    return (
      <List>
        {leaves.map((item) => (
          <>
            {item.data.status === "pending" && (
              <LeaveCard key={item.data.identifier} item={item.data} />
            )}
          </>
        ))}
      </List>
    );
  }
  function renderApprovedLeaveList(leaves) {
    console.log("render closed leave list*********", leaves);
    return (
      <List>
        {leaves.map((item) => (
          <>
            {item.data.status === "approved" && (
              // <LeaveCard key={item.data.identifier} item={item.data} />
              <div className="leaveListPendingCard">
                <div className="leaveListPendingCardHeader">
                  <div className="leaveListPendingCardProfile">
                    {/* <img src={avatar} /> */}
                    <Avatar
                      className={classes.avatar}
                      style={{
                        backgroundColor: "#214b87",
                      }}
                    >
                      {item.data.name && item.data.name.charAt(0)}
                    </Avatar>
                    <div>
                      <div className="leaveListPendingCardProfileName">
                        {item.data.name}
                      </div>
                      {/* <div className="leaveListPendingCardProfileDesignation">
                      8138858555
                    </div> */}
                    </div>
                  </div>
                  <div className="leaveApprovedIndicator">
                    {item.data.status}
                  </div>
                </div>
                <div className="leaveListPendingCardBody">
                  <div>
                    <div className="InfoLabel">From</div>
                    <div className="InfoData">{item.data.startDate}</div>
                  </div>
                  <div>
                    <div className="InfoLabel"> To</div>
                    <div className="InfoData">{item.data.endDate}</div>
                  </div>
                  <div>
                    <div className="InfoLabel">Days</div>
                    <div className="InfoData">
                      {getDifferenceInDays(
                        item.data.startDate,
                        item.data.endDate
                      )}
                    </div>
                  </div>
                </div>
                <div className="leaveListPendingCardFooter">
                  <div className="InfoLabel">Purpose</div>
                  <div className="InfoData">{item.data.requestComment}</div>
                </div>
              </div>
            )}
          </>
        ))}
      </List>
    );
  }
  function renderRejectedLeaveList(leaves) {
    return (
      <List>
        {leaves.map((item) => (
          <>
            {item.data.status === "rejected" && (
              <div className="leaveListPendingCard">
                <div className="leaveListPendingCardHeader">
                  <div className="leaveListPendingCardProfile">
                    {/* <img src={avatar} /> */}
                    <Avatar
                      className={classes.avatar}
                      style={{
                        backgroundColor: "#214b87",
                      }}
                    >
                      {item.data.name && item.data.name.charAt(0)}
                    </Avatar>
                    <div>
                      <div className="leaveListPendingCardProfileName">
                        {item.data.name}
                      </div>
                      {/* <div className="leaveListPendingCardProfileDesignation">
                      8138858555
                    </div> */}
                    </div>
                  </div>
                  <div className="leaveRejectedIndicator">
                    {item.data.status}
                  </div>
                </div>
                <div className="leaveListPendingCardBody">
                  <div>
                    <div className="InfoLabel">From</div>
                    <div className="InfoData">{item.data.startDate}</div>
                  </div>
                  <div>
                    <div className="InfoLabel"> To</div>
                    <div className="InfoData">{item.data.endDate}</div>
                  </div>
                  <div>
                    <div className="InfoLabel">Days</div>
                    <div className="InfoData">
                      {getDifferenceInDays(
                        item.data.startDate,
                        item.data.endDate
                      )}
                    </div>
                  </div>
                </div>
                <div className="leaveListPendingCardFooter">
                  <div className="InfoLabel">Purpose</div>
                  <div className="InfoData">{item.data.requestComment}</div>
                </div>
                <div className="leaveListPendingCardFooter">
                  <div className="InfoLabel">Reason for rejection</div>
                  <div className="InfoData">{item.data.rejectionComment}</div>
                </div>
              </div>
            )}
          </>
        ))}
      </List>
    );
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      const query = ``;
      await dispatch(get_all_leave(activeOuid, query));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <>
      <HeaderInner pageTitle="Leaves" onClick={() => {history.push("/")}}/>
      <div className="container--narrow HrmAppPage">
        {/* <div className={classes.attendanceButtonWrapper}>
          <h1></h1>
          { usertype === 'admin' && 
          <div className={classes.attendanceButton}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="default"
              aria-label="menu"
              onClick={() => history.push(`/${activeOuid}/leavesetup`)}
            >
              <SettingsIcon />
            </IconButton>
          </div>
          }
        </div> */}
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="PENDING" {...a11yProps(0)} />
            <Tab label="APPROVED" {...a11yProps(1)} />
            <Tab label="REJECTED" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            {showGlobalLoader ? (
              <Loader />
            ) : (
              <>
                {leaves.length !== 0
                  ? renderPendingLeaveList(leaves)
                  : "No leaves found..."}
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {showGlobalLoader ? (
              <Loader />
            ) : (
              <>
                {leaves.length !== 0
                  ? renderApprovedLeaveList(leaves)
                  : "No leaves found..."}
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            {showGlobalLoader ? (
              <Loader />
            ) : (
              <>
                {leaves.length !== 0
                  ? renderRejectedLeaveList(leaves)
                  : "No leaves found..."}
              </>
            )}
          </TabPanel>
        </SwipeableViews>
        <div className="HrmAppHomePageButtonWrapper">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => history.push(`/edwin/applyleave`)}
          >
            Apply Leave
          </Button>
        </div>
      </div>
      <Footer />
      <CommonSnackBar/>
    </>
  );
}
