import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { history } from '../../../../reducers';
import configureStore from "../../../../store/configureStore";

import { attendance } from '../reducers/reducer-attendance';

const store = configureStore();

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      margin: '0 auto',
    },
  }));
let colorHolder = [
  // "#F7941D","#940A73", #1976d2,
  "#214B87",
  // "#34785C", "#F71D1D", "#8F00B5", "#E01E5A", "#36C5F0"
  ];
export default function  StaffAttendanceCard(props){
    const { item, status } = props;
    useEffect(() => {
      // console.log("props", props);
    });
    const { activeOuid } = store.getState().permission;
    
    const renderStatus = (status) => {
      console.log("Username", status);
      if(status === 'P'){
        return(
          <div className="timelineDot present">Present</div>
        )
      }
      if(status === 'A'){
        return(
          <div className="timelineDot apsend">Absent</div>
        )
      }
      else{
        return(
          <div className="timelineDot noupdate">Not marked</div>
        )
      }
 
    }

    return (
        <>
          <ListItem
              key={props}
              onClick={() => history.push(`/${activeOuid}/attendancelog/${item.data.username}`)}
              >
              <ListItemAvatar>
                  <Avatar
                    style={{
                      backgroundColor: colorHolder[Math.floor(Math.random() * 1)]
                    }}>
                      {item.data.name.charAt(0)}
                  </Avatar>
              </ListItemAvatar>
              <ListItemText
                  primary={item.data.name}
                  secondary={item.data.phone_number}
              />
              {/* <div className="timelineDot noupdate">{item.data.attendanceStatusCode}</div> */}
              {renderStatus(item.data.attendanceStatusCode)}
          </ListItem>
        </>
      
    );
}
