import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import configureStore from "../../../store/configureStore";
import CommonSnackBar from "../../common/CommonSnackBar";
import { Avatar, Button } from "@material-ui/core";
import "./App.css";
import { history } from "../../../reducers";
import InOutSwitch from "./common/InOutSwitch";
import { get_my_attendance } from "./actions/action-attendance";
import staffsIcon from "./images/Staffs.svg";
import attendanceIcon from "./images/Attendence.svg";
import leaveIcon from "./images/Leaves.svg";
import payrollIcon from "./images/Payroll.svg";
import banner from "./images/Banner.svg";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { makeStyles } from "@material-ui/core/styles";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
    marginRight: 8,
  },
}));

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("");
}

export default function Home() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { activeOuid } = store.getState().permission;
  const { details } = store.getState().myProfile;
  const { policies } = store.getState().permission;
  console.log("policies", store.getState().permission);
  var usertype = policies[0].split("=");
  usertype = usertype[1];
  const username = details.data.username;
  const { myAttendance } = useSelector((state) => state.attendance);

  useEffect(() => {
    const today = convert(new Date());
    const query = `username=${username}&date=${today}`;
    const fetchData = async (dispatch) => {
      await dispatch(get_my_attendance(activeOuid, query));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div className="">
      <Header />
      <div className="HrmAppHomePage HrmAppPage container--narrow">
        <div className="HrmAppHeaderProfileWrapper">
          <div className="HrmAppHeaderProfileDetails w_100">
            {/* <img src={avatar} /> */}
            <Avatar
              className={classes.avatar}
              style={{
                backgroundColor: "#214b87",
              }}
            >
              {details.data.name && details.data.name.charAt(0)}
            </Avatar>
            <div>
              <div className="HrmAppHeaderProfileName">{details.data.name}</div>
              <div className="HrmAppHeaderProfileDesignation">
                {details.data.username}
              </div>
            </div>
          </div>
        </div>
        <div className="HrmAppHomeListWrapper">
          {usertype === "admin" ? (
            <div>
              <Link to={`/${activeOuid}/staff`} className="HrmAppHomeListItem">
                <img src={staffsIcon} />
              </Link>
              <p>Employees</p>
            </div>
          ) : (
            <></>
          )}

          {usertype === "admin" ? (
            <div>
              <Link
                to={`/${activeOuid}/attendance`}
                className="HrmAppHomeListItem"
              >
                <img src={attendanceIcon} />
              </Link>
              <p>Attendance</p>
            </div>
          ) : (
            <div>
              <Link
                to={`/${activeOuid}/attendancelog/${username}`}
                className="HrmAppHomeListItem"
              >
                <img src={attendanceIcon} />
              </Link>
              <p>Attendance</p>
            </div>
          )}

          <div>
            <Link to={`/${activeOuid}/leave`} className="HrmAppHomeListItem">
              <img src={leaveIcon} />
            </Link>
            <p>Leave</p>
          </div>
          {usertype === "admin" ? (
            <div>
              <Link
                to={`/${activeOuid}/payroll`}
                className="HrmAppHomeListItem"
              >
                <img src={payrollIcon} />
              </Link>
              <p>Payroll</p>
            </div>
          ) : (
            <div>
              <Link
                to={`/${activeOuid}/payrollprofile/${username}`}
                className="HrmAppHomeListItem"
              >
                <img src={payrollIcon} />
              </Link>
              <p>Payroll</p>
            </div>
          )}
        </div>
        <div className="HrmAppBanner">
          <img src={banner} />
        </div>
        {myAttendance ? (
          <InOutSwitch attendancedetails={myAttendance} />
        ) : (
          <></>
        )}
        {usertype === "admin" ? (
          <div className="HrmAppHomePageButtonWrapper">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => history.push(`/edwin/addstaff`)}
            >
              Add Employees
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
