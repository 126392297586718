export const RESET_STATE = "RESET_STATE";

export const REGISTER_STAFF_REQUESTED = "REGISTER_STAFF_REQUESTED";
export const REGISTER_STAFF_SUCCESS = "REGISTER_STAFF_SUCCESS";
export const REGISTER_STAFF_ERRORED = "REGISTER_STAFF_ERRORED";

export const GET_ALL_STAFF_REQUESTED = "GET_ALL_STAFF_REQUESTED";
export const GET_ALL_STAFF_SUCCESS = "GET_ALL_STAFF_SUCCESS";
export const GET_ALL_STAFF_ERRORED = "GET_ALL_STAFF_ERRORED";

export const GET_STAFF_DETAILS_REQUESTED = "GET_STAFF_DETAILS_REQUESTED";
export const GET_STAFF_DETAILS_SUCCESS = "GET_STAFF_DETAILS_SUCCESS";
export const GET_STAFF_DETAILS_ERRORED = "GET_STAFF_DETAILS_ERRORED";

export const UPDATE_STAFF_REQUESTED = "UPDATE_STAFF_REQUESTED";
export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS";
export const UPDATE_STAFF_ERRORED = "UPDATE_STAFF_ERRORED";

export const DELETE_STAFF_REQUESTED = "DELETE_STAFF_REQUESTED";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const DELETE_STAFF_ERRORED = "DELETE_STAFF_ERRORED";

// Attendance
export const MARK_ATTENDANCE_REQUESTED = "MARK_ATTENDANCE_REQUESTED";
export const MARK_ATTENDANCE_SUCCESS = "MARK_ATTENDANCE_SUCCESS";
export const MARK_ATTENDANCE_ERRORED = "MARK_ATTENDANCE_ERRORED";

export const GET_ALL_ATTENDANCE_REQUESTED = "GET_ALL_ATTENDANCE_REQUESTED";
export const GET_ALL_ATTENDANCE_SUCCESS = "GET_ALL_ATTENDANCE_SUCCESS";
export const GET_ALL_ATTENDANCE_ERRORED = "GET_ALL_ATTENDANCE_ERRORED";

export const GET_MY_ATTENDANCE_REQUESTED = "GET_MY_ATTENDANCE_REQUESTED";
export const GET_MY_ATTENDANCE_SUCCESS = "GET_MY_ATTENDANCE_SUCCESS";
export const GET_MY_ATTENDANCE_ERRORED = "GET_MY_ATTENDANCE_ERRORED";

export const PUNCH_IN_OUT_REQUESTED = "PUNCH_IN_OUT_REQUESTED";
export const PUNCH_IN_OUT_SUCCESS = "PUNCH_IN_OUT_SUCCESS";
export const PUNCH_IN_OUT_ERRORED = "PUNCH_IN_OUT_ERRORED";

export const UPDATE_ATTENDANCE_CONFIG_REQUESTED =
  "UPDATE_ATTENDANCE_CONFIG_REQUESTED";
export const UPDATE_ATTENDANCE_CONFIG_SUCCESS =
  "UPDATE_ATTENDANCE_CONFIG_SUCCESS";
export const UPDATE_ATTENDANCE_CONFIG_ERRORED =
  "UPDATE_ATTENDANCE_CONFIG_ERRORED";

export const CREATE_ATTENDANCE_CONFIG_REQUESTED =
  "CREATE_ATTENDANCE_CONFIG_REQUESTED";
export const CREATE_ATTENDANCE_CONFIG_SUCCESS =
  "CREATE_ATTENDANCE_CONFIG_SUCCESS";
export const CREATE_ATTENDANCE_CONFIG_ERRORED =
  "CREATE_ATTENDANCE_CONFIG_ERRORED";

export const GET_ATTENDANCE_CONFIG_REQUESTED =
  "GET_ATTENDANCE_CONFIG_REQUESTED";
export const GET_ATTENDANCE_CONFIG_SUCCESS = "GET_ATTENDANCE_CONFIG_SUCCESS";
export const GET_ATTENDANCE_CONFIG_ERRORED = "GET_ATTENDANCE_CONFIG_ERRORED";

// Leave
export const APPLY_LEAVE_REQUESTED = "APPLY_LEAVE_REQUESTED";
export const APPLY_LEAVE_SUCCESS = "APPLY_LEAVE_SUCCESS";
export const APPLY_LEAVE_ERRORED = "APPLY_LEAVE_ERRORED";

export const GET_ALL_LEAVE_REQUESTED = "GET_ALL_LEAVE_REQUESTED";
export const GET_ALL_LEAVE_SUCCESS = "GET_ALL_LEAVE_SUCCESS";
export const GET_ALL_LEAVE_ERRORED = "GET_ALL_LEAVE_ERRORED";

export const GET_MY_LEAVE_REQUESTED = "GET_MY_LEAVE_REQUESTED";
export const GET_MY_LEAVE_SUCCESS = "GET_MY_LEAVE_SUCCESS";
export const GET_MY_LEAVE_ERRORED = "GET_MY_LEAVE_ERRORED";

export const UPDATE_LEAVE_REQUESTED = "UPDATE_LEAVE_REQUESTED";
export const UPDATE_LEAVE_SUCCESS = "UPDATE_LEAVE_SUCCESS";
export const UPDATE_LEAVE_ERRORED = "UPDATE_LEAVE_ERRORED";

export const GET_LEAVE_CONFIG_REQUESTED = "GET_LEAVE_CONFIG_REQUESTED";
export const GET_LEAVE_CONFIG_SUCCESS = "GET_LEAVE_CONFIG_SUCCESS";
export const GET_LEAVE_CONFIG_ERRORED = "GET_LEAVE_CONFIG_ERRORED";

export const CREATE_LEAVE_CONFIG_REQUESTED = "CREATE_LEAVE_CONFIG_REQUESTED";
export const CREATE_LEAVE_CONFIG_SUCCESS = "CREATE_LEAVE_CONFIG_SUCCESS";
export const CREATE_LEAVE_CONFIG_ERRORED = "CREATE_LEAVE_CONFIG_ERRORED";

export const UPDATE_LEAVE_CONFIG_REQUESTED = "UPDATE_LEAVE_CONFIG_REQUESTED";
export const UPDATE_LEAVE_CONFIG_SUCCESS = "UPDATE_LEAVE_CONFIG_SUCCESS";
export const UPDATE_LEAVE_CONFIG_ERRORED = "UPDATE_LEAVE_CONFIG_ERRORED";

// Payroll
export const CREATE_PAYROLL_PROFILE_REQUESTED =
  "CREATE_PAYROLL_PROFILE_REQUESTED";
export const CREATE_PAYROLL_PROFILE_SUCCESS = "CREATE_PAYROLL_PROFILE_SUCCESS";
export const CREATE_PAYROLL_PROFILE_ERRORED = "CREATE_PAYROLL_PROFILE_ERRORED";

export const GET_PAYROLL_REQUESTED = "GET_PAYROLL_REQUESTED";
export const GET_PAYROLL_SUCCESS = "GET_PAYROLL_SUCCESS";
export const GET_PAYROLL_ERRORED = "GET_PAYROLL_ERRORED";

export const GET_PAYROLL_PROFILE_REQUESTED = "GET_PAYROLL_PROFILE_REQUESTED";
export const GET_PAYROLL_PROFILE_SUCCESS = "GET_PAYROLL_PROFILE_SUCCESS";
export const GET_PAYROLL_PROFILE_ERRORED = "GET_PAYROLL_PROFILE_ERRORED";

export const UPDATE_PAYROLL_PROFILE_REQUESTED =
  "UPDATE_PAYROLL_PROFILE_REQUESTED";
export const UPDATE_PAYROLL_PROFILE_SUCCESS = "UPDATE_PAYROLL_PROFILE_SUCCESS";
export const UPDATE_PAYROLL_PROFILE_ERRORED = "UPDATE_PAYROLL_PROFILE_ERRORED";

export const CREATE_PAYROLL_INSTANCE_REQUESTED =
  "CREATE_PAYROLL_INSTANCE_REQUESTED";
export const CREATE_PAYROLL_INSTANCE_SUCCESS =
  "CREATE_PAYROLL_INSTANCE_SUCCESS";
export const CREATE_PAYROLL_INSTANCE_ERRORED =
  "CREATE_PAYROLL_INSTANCE_ERRORED";

export const GET_ALL_PAYROLL_INSTANCE_REQUESTED =
  "GET_ALL_PAYROLL_INSTANCE_REQUESTED";
export const GET_ALL_PAYROLL_INSTANCE_SUCCESS =
  "GET_ALL_PAYROLL_INSTANCE_SUCCESS";
export const GET_ALL_PAYROLL_INSTANCE_ERRORED =
  "GET_ALL_PAYROLL_INSTANCE_ERRORED";

export const UPDATE_PAYROLL_INSTANCE_REQUESTED =
  "UPDATE_PAYROLL_INSTANCE_REQUESTED";
export const UPDATE_PAYROLL_INSTANCE_SUCCESS =
  "UPDATE_PAYROLL_INSTANCE_SUCCESS";
export const UPDATE_PAYROLL_INSTANCE_ERRORED =
  "UPDATE_PAYROLL_INSTANCE_ERRORED";
