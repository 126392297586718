import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mark_attendance, punch_in_out } from "../actions/action-attendance";
import configureStore from "../../../../store/configureStore";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import FingerprintIcon from "@material-ui/icons/Fingerprint";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  attendanceButtonWrapper: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonGreen: {
    backgroundColor: theme.mycolor.green.main,
    "&:hover": {
      backgroundColor: theme.mycolor.green.light,
    },
  },
  buttonRed: {
    backgroundColor: theme.mycolor.red.main,
    "&:hover": {
      backgroundColor: theme.mycolor.red.light,
    },
  },
  attendanceButtonInliner: {
    display: "flex",
    justifyContent: "center",
    gap: 10,
    alignItems: "center",
    fontWeight: "bold",
    flexDirection: "column",
  },
  red: {
    color: theme.mycolor.red.main,
  },
  green: {
    color: theme.mycolor.green.main,
  },
}));

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("");
}

export default function InOutSwitch(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { details } = store.getState().myProfile;
  const { activeOuid } = store.getState().permission;

  let today = new Date();
  let hour = new Date().getHours();
  let minutes =
    today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
  console.log("minutes", minutes);

  const attendanceProps = props.attendancedetails[0];

  const [attendanceDetails, setAttendanceDetails] = React.useState(
    props.attendancedetails[0]
  );

  const [attendancePost, setAttendancePost] = React.useState({
    username: details.data.username,
    statusCode: "P",
    date: convert(today),
    phone: details.data.phone_number,
    name: details.data.name,
    logStatus: "in",
    logs: [
      {
        time: `${hour}${minutes}`,
        type: "in",
      },
    ],
  });

  const handleAttendance = () => {
    dispatch(mark_attendance(attendancePost, activeOuid));
    console.log("Before put", attendancePost);
  };

  const handlePunchIn = () => {
    if (props.attendancedetails[0]) {
      const data = props.attendancedetails[0].data;
      data.logStatus = "in";
      data.logs.push({
        time: `${hour}${minutes}`,
        type: "in",
      });
      const entitytype = props.attendancedetails[0].EntityType;
      const myAttendance = {
        data: data,
        EntityType: entitytype,
      };
      console.log("myAttendance", myAttendance);
      dispatch(punch_in_out(myAttendance, data.identifier, activeOuid));
    }
  };

  const handlePunchOut = () => {
    if (props.attendancedetails[0]) {
      const data = props.attendancedetails[0].data;
      data.logStatus = "out";
      data.logs.push({
        time: `${hour}${minutes}`,
        type: "out",
      });
      const entitytype = props.attendancedetails[0].EntityType;
      const myAttendance = {
        data: data,
        EntityType: entitytype,
      };
      console.log("myAttendance", myAttendance);
      dispatch(punch_in_out(myAttendance, data.identifier, activeOuid));
    }
  };
  useEffect(() => {}, [dispatch]);

  return (
    <div className={classes.attendanceButtonWrapper}>
      <div className={classes.attendanceButtonInliner}>
        {attendanceProps ? (
          <>
            {attendanceProps.data.logStatus === "in" && (
              <>
                <div className={classes.green}>You are IN.</div>
                <div className={classes.attendanceButton}>
                  <Button
                    // size="small"
                    variant="contained"
                    color="primary"
                    className={classes.buttonRed}
                    startIcon={<FingerprintIcon />}
                    onClick={() => handlePunchOut()}
                  >
                    Punch Out
                  </Button>
                </div>
              </>
            )}
            {attendanceProps.data.logStatus === "out" && (
              <>
                <div className={classes.red}>You are OUT.</div>
                <div className={classes.attendanceButton}>
                  <Button
                    // size="small"
                    variant="contained"
                    color="primary"
                    className={classes.buttonGreen}
                    startIcon={<FingerprintIcon />}
                    onClick={() => handlePunchIn()}
                  >
                    Punch In
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className={classes.red}>
              You have not marked your attendance
            </div>
            <div className={classes.attendanceButton}>
              <Button
                // size="small"
                variant="contained"
                color="primary"
                className={classes.buttonGreen}
                startIcon={<FingerprintIcon />}
                onClick={() => handleAttendance()}
              >
                Mark attendance
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
