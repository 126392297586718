import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { history } from "../../../../reducers";
import avatar from "../images/Frame.svg";
import { Avatar, Button, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { update_leave } from "../actions/action-leave";
import configureStore from "../../../../store/configureStore";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  rejectionButtonWrapper: {
    marginTop: 10,
    display: "flex",
    gap: 15,
  },
  buttonGreen: {
    backgroundColor: theme.mycolor.green.main,
    "&:hover": {
      backgroundColor: theme.mycolor.green.light,
    },
  },
  buttonRed: {
    backgroundColor: theme.mycolor.red.main,
    "&:hover": {
      backgroundColor: theme.mycolor.red.light,
    },
  },
  avatar: {
    textTransform: "uppercase",
    marginRight: 8,
  },
  error: {
    color: "red",
    marginTop: 5,
  },
}));

export default function LeaveCard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rejection, setRejection] = useState(false);
  const [error, setError] = useState("");
  const { activeOuid } = store.getState().permission;
  const { policies } = store.getState().permission;
  var usertype = policies[0].split("=");
  usertype = usertype[1];
  console.log("permissions", usertype);

  const [state, setState] = useState({
    rejectionComment: "",
  });

  const leave = props.item;
  console.log("props", leave);

  function getDifferenceInDays(startDate, endDate) {
    console.log("startDate", startDate);
    console.log("endDate", endDate);
    console.log("startHalf", props.item.startHalf);
    console.log("endHalf", props.item.endHalf);

    const [yearSt, monthSt, daySt] = startDate.split("-");
    const sDate = new Date(yearSt, monthSt - 1, daySt);

    const [yearEnd, monthEnd, dayEnd] = endDate.split("-");
    const eDate = new Date(yearEnd, monthEnd - 1, dayEnd);

    return getBusinessDatesCount(sDate, eDate);
  }

  const getBusinessDatesCount = (startDate, endDate) => {
    let count = 0;
    let curDate = +startDate;
    while (curDate <= +endDate) {
      const dayOfWeek = new Date(curDate).getDay();
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
      if (!isWeekend) {
        count++;
      }
      curDate = curDate + 24 * 60 * 60 * 1000;
    }
    return count;
  };

  function handleLeaveApproval() {
    leave.status = "approved";
    dispatch(update_leave(activeOuid, leave, leave.identifier));
  }

  function handleLeaveReject() {
    if (!state.rejectionComment) {
      setError("Reason for rejection is required");
      return;
    }
    leave.rejectionComment = state.rejectionComment;
    leave.status = "rejected";
    dispatch(update_leave(activeOuid, leave, leave.identifier));
  }

  function handleRejectionUi() {
    setRejection(true);
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
    setError(""); // Clear error when user starts typing
  };

  return (
    <div className="leaveListPendingCard">
      <div className="leaveListPendingCardHeader">
        <div className="leaveListPendingCardProfile">
          <Avatar
            className={classes.avatar}
            style={{
              backgroundColor: "#214b87",
            }}
          >
            {props.item.name && props.item.name.charAt(0)}
          </Avatar>
          <div>
            <div className="leaveListPendingCardProfileName">
              {props.item.name}
            </div>
          </div>
        </div>
        <div className="leaveListPendingCardLeavetype">
          {props.item.leaveType}
        </div>
      </div>
      <div className="leaveListPendingCardBody">
        <div>
          <div className="InfoLabel">From</div>
          <div className="InfoData">{props.item.startDate}</div>
        </div>
        <div>
          <div className="InfoLabel">To</div>
          <div className="InfoData">{props.item.endDate}</div>
        </div>
        <div>
          <div className="InfoLabel">Days</div>
          <div className="InfoData">
            {getDifferenceInDays(props.item.startDate, props.item.endDate)}
          </div>
        </div>
      </div>
      <div className="leaveListPendingCardFooter">
        <div className="InfoLabel">Purpose</div>
        <div className="InfoData">{props.item.requestComment}</div>
      </div>
      {usertype === "admin" ? (
        rejection ? (
          <div>
            <h4>Reject Leave Request</h4>
            <TextField
              fullWidth
              id="rejectionComment"
              label="Reason for rejection"
              multiline
              rows={4}
              onChange={handleChange}
              variant="outlined"
              required
              error={!!error}
              helperText={error}
            />
            <div className={classes.rejectionButtonWrapper}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleLeaveReject}
                className={classes.buttonRed}
              >
                Reject
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => setRejection(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div className="leaveListPendingCardButtonWrap">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleLeaveApproval}
              className={classes.buttonGreen}
            >
              APPROVE
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleRejectionUi}
              className={classes.buttonRed}
            >
              REJECT
            </Button>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
}
