import {
  APPLY_LEAVE_REQUESTED,
  APPLY_LEAVE_SUCCESS,
  APPLY_LEAVE_ERRORED,
  GET_ALL_LEAVE_REQUESTED,
  GET_ALL_LEAVE_SUCCESS,
  GET_ALL_LEAVE_ERRORED,
  GET_MY_LEAVE_REQUESTED,
  GET_MY_LEAVE_SUCCESS,
  GET_MY_LEAVE_ERRORED,
  UPDATE_LEAVE_REQUESTED,
  UPDATE_LEAVE_SUCCESS,
  UPDATE_LEAVE_ERRORED,
  CREATE_LEAVE_CONFIG_REQUESTED,
  CREATE_LEAVE_CONFIG_SUCCESS,
  CREATE_LEAVE_CONFIG_ERRORED,
  GET_LEAVE_CONFIG_REQUESTED,
  GET_LEAVE_CONFIG_SUCCESS,
  GET_LEAVE_CONFIG_ERRORED,
  UPDATE_LEAVE_CONFIG_REQUESTED,
  UPDATE_LEAVE_CONFIG_SUCCESS,
  UPDATE_LEAVE_CONFIG_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const apply_leave_requested = () => ({
  type: APPLY_LEAVE_REQUESTED,
});

export const apply_leave_success = () => ({
  type: APPLY_LEAVE_SUCCESS,
});

export const apply_leave_errored = (error) => ({
  type: APPLY_LEAVE_ERRORED,
  error,
});

export const get_all_leave_requested = () => ({
  type: GET_ALL_LEAVE_REQUESTED,
});

export const get_all_leave_success = (payload) => ({
  type: GET_ALL_LEAVE_SUCCESS,
  payload,
});

export const get_all_leave_errored = (error) => ({
  type: GET_ALL_LEAVE_ERRORED,
  error,
});

export const get_my_leave_requested = () => ({
  type: GET_MY_LEAVE_REQUESTED,
});

export const get_my_leave_success = (payload) => ({
  type: GET_MY_LEAVE_SUCCESS,
  payload,
});

export const get_my_leave_errored = (error) => ({
  type: GET_MY_LEAVE_ERRORED,
  error,
});

export const update_leave_requested = () => ({
  type: UPDATE_LEAVE_REQUESTED,
});

export const update_leave_success = (payload) => ({
  type: UPDATE_LEAVE_SUCCESS,
  payload,
});

export const update_leave_errored = (error) => ({
  type: UPDATE_LEAVE_ERRORED,
  error,
});

export const create_leave_config_requested = () => ({
  type: CREATE_LEAVE_CONFIG_REQUESTED,
});

export const create_leave_config_success = (payload) => ({
  type: CREATE_LEAVE_CONFIG_SUCCESS,
  payload,
});

export const create_leave_config_errored = (error) => ({
  type: CREATE_LEAVE_CONFIG_ERRORED,
  error,
});

export const get_leave_config_requested = () => ({
  type: GET_LEAVE_CONFIG_REQUESTED,
});

export const get_leave_config_success = (payload) => ({
  type: GET_LEAVE_CONFIG_SUCCESS,
  payload,
});

export const get_leave_config_errored = (error) => ({
  type: GET_LEAVE_CONFIG_ERRORED,
  error,
});

export const update_leave_config_requested = () => ({
  type: UPDATE_LEAVE_CONFIG_REQUESTED,
});

export const update_leave_config_success = (payload) => ({
  type: UPDATE_LEAVE_CONFIG_SUCCESS,
  payload,
});

export const update_leave_config_errored = (error) => ({
  type: UPDATE_LEAVE_CONFIG_ERRORED,
  error,
});

export const apply_leave =
  (payload, activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(apply_leave_requested());
    console.log(`mark_attendance_requested`);
    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/leave`;
      const method = "post";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("Leave applied Successfully");
          dispatch(showSnackbar("Leave applied Successfully", "success"));
          dispatch(apply_leave_success());
          // dispatch(get_all_leave(activeOuid, query));
        })
        .catch((err) => {
          console.log("Leave application Failed");
          dispatch(showSnackbar("Leave application Failed", "error"));
          dispatch(apply_leave_errored(err));
        });
    }
    return null;
  };

export const get_all_leave = (activeOuid, query) => async (dispatch) => {
  dispatch(get_all_leave_requested());
  const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
  if (hrmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${hrmApiEndpoint}/leave${query}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get All leave Success");
        dispatch(get_all_leave_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get All leave Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get All leave Failed", "error"));
        }
        dispatch(get_all_leave_errored(err));
      });
  }
  return null;
};

export const get_my_leave = (activeOuid, query) => async (dispatch) => {
  dispatch(get_my_leave_requested());
  const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
  if (hrmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${hrmApiEndpoint}/leave?${query}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get My leave Success", response.data.items);
        dispatch(get_my_leave_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get My leave Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get My leave Failed", "error"));
        }
        dispatch(get_my_leave_errored(err));
      });
  }
  return null;
};

export const update_leave =
  (activeOuid, payload, identifier) => async (dispatch) => {
    dispatch(update_leave_requested());
    const payloadData = { data: payload };
    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/leave/${identifier}`;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, payloadData, axiosHeader)
        .then((response) => {
          console.log("Update leave Success");
          if (payload.status === "rejected") {
            dispatch(showSnackbar("Leave rejected successfully", "success"));
          } else {
            dispatch(showSnackbar("Leave approved successfully", "success"));
          }          
          dispatch(update_leave_success(response.data.items));
        })
        .catch((err) => {
          console.log("Update leave Failed");
          if (payload.status === "rejected") {
            dispatch(showSnackbar("Leave rejection failed", "error"));
          } else {
            dispatch(showSnackbar("Leave approval failed", "error"));
          }
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Update leave Failed", "error"));
          }
          dispatch(update_leave_errored(err));
        });
    }
    return null;
  };

export const create_leave_config =
  (payload, activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(create_leave_config_requested());
    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/config`;
      const method = "post";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("leave config updated Successfully");
          dispatch(
            showSnackbar("leave config updated Successfully", "success")
          );
          dispatch(create_leave_config_success());
        })
        .catch((err) => {
          console.log("Attendance config updated Failed");
          dispatch(showSnackbar("Attendance config updated", "error"));
          dispatch(create_leave_config_errored(err));
        });
    }
    return null;
  };

export const get_leave_config = (activeOuid) => async (dispatch) => {
  dispatch(get_leave_config_requested());
  const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
  if (hrmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${hrmApiEndpoint}/config?type=leave`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("get_leave_config Success", response.data.items);
        dispatch(get_leave_config_success(response.data.items));
      })
      .catch((err) => {
        console.log("get_leave_config Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("get_leave_config Failed", "error"));
        }
        dispatch(get_leave_config_errored(err));
      });
  }
  return null;
};
