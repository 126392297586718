import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";

import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import "./App.css";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { register_staff } from "./actions/action-staff";
import { history } from "../../../reducers";
import { is } from "immutable";
import Footer from "./Footer";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
    background: "#fff",
    padding: "20px 10px",
    borderRadius: "8px",
  },
  input: {
    marginBottom: 10,
  },
}));

export default function AddStaff() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = React.useState();

  const [state, setState] = React.useState({
    appRole: "hrm=user",
    name: "",
    phone_number: "",
    username: "",
    jobTitle: "",
  });

  const { activeOuid } = useSelector((state) => state.permission);

  const handleCheck = (event) => {
    // setChecked(event.target.checked);
    console.log("[event.target.checked]", event.target.checked);
    if (event.target.checked) {
      console.log("Before set state", state);
      setState({ ...state, appRole: "hrm=admin" });
      console.log("After set state", state);
    } else {
      setState({ ...state, appRole: "hrm=user" });
    }
    console.log("state", state);
  };

  const handleChange = (event) => {
    console.log("state", state);
    setState({ ...state, [event.target.id]: event.target.value });
  };

  function validatePhoneNumber(input_str) {
    console.log("input_str", input_str);
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return re.test(input_str);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("addStaffHandleClick", state);
    if (validatePhoneNumber(state.phone_number)) {
      dispatch(register_staff(state, activeOuid));
      history.push(`/${activeOuid}/staff`);
    } else {
      setError("Phone number not valid");
    }
  };

  return (
    <>
      <HeaderInner pageTitle="Add staff" />
      <div className="HrmAppPage container--narrow">
        <form
          className={classes.root}
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          {error && <div className="form_error">{error}</div>}
          <TextField
            id="name"
            label="Name"
            required
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
          />
          <TextField
            id="phone_number"
            label="Phone Number"
            required
            fullWidth
            variant="outlined"
            className={classes.input}
            inputProps={{
              maxLength: 10,
              minLength: 10,
            }}
            onChange={handleChange}
          />
          <TextField
            id="username"
            label="Email"
            required
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            type="email"
          />
          <TextField
            id="jobTitle"
            label="Job Title"
            required
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheck}
                  id="isAdmin"
                  name="checked"
                  color="primary"
                />
              }
              label="Admin"
            />
            <div></div>
          </FormGroup>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            // onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </form>
      </div>
      <Footer />
    </>
  );
}
