import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Drawer, List } from "@material-ui/core";
import HeaderInner from "./HeaderInner";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import configureStore from "../../../store/configureStore";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import { get_staff_details } from "./actions/action-staff";
import Footer from "./Footer";
import { get_payroll_instance } from "./actions/action-payroll";
import { get_my_attendance } from "./actions/action-attendance";
import AttendanceLog from "./AttendanceLog";
import Payslip from "./common/Payslip";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import LeaveCard from "./common/LeaveCard";
import { get_all_leave, get_my_leave } from "./actions/action-leave";
import PaySlipNew from "./common/PaySlipNew";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
  },
  attendanceButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  tabs: {
    background: "#fff",
    boxShadow: "none",
  },
  tab: {
    borderBottom: "1.98502px solid #4B4DED",
  },
  tabpanel: {
    padding: "0px",
  },
  tabHead: {
    boxShadow: "none",
    borderTop: "1px solid #eee",
  },
  avatar: {
    textTransform: "uppercase",
  },
}));

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2);
  return [date.getFullYear(), mnth].join("");
}

const store = configureStore();

export default function StaffProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { activeOuid } = store.getState().permission;
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);
  const [value, setValue] = React.useState(0);
  const { staffdetails } = useSelector((state) => state.staffs);
  const { leaves, showGlobalLoader } = useSelector((state) => state.leaves);
  const [selectedDate, handleDateChange] = useState(new Date());
  const initialPayrollProfile = {};

  const { payrollinstanceprofile } = useSelector((state) => state.payroll);
  console.log("payrollinstanceprofile", payrollinstanceprofile);
  let staffId = window.location.pathname.split("/");
  staffId = staffId[3];

  function renderLeaveList(leaves) {
    return (
      <List>
        {leaves.map((item) => (
          <>
            <LeaveCard key={item.data.identifier} item={item.data} />
          </>
        ))}
      </List>
    );
  }

  const onDateChange = (event) => {
    handleDateChange(event);
    console.log("Date change event", event);
    fetchPayroll(dispatch, event);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const fetchPayroll = async (dispatch, date) => {
    console.log("fetchPayroll", fetchPayroll);
    const month = convert(date);
    const query = `month=${month}&username=${staffId}`;
    await dispatch(get_payroll_instance(activeOuid, query));
    // setPayrollinstanceState(store.getState().payroll.payrollinstanceprofile);
  };

  useEffect(() => {
    const queryLeave = `username=${staffId}`;
    const month = convert(selectedDate);
    const query = `month=${month}&username=${staffId}`;

    const fetchData = async (dispatch) => {
      await dispatch(get_staff_details(activeOuid, staffId));
      await dispatch(get_my_leave(activeOuid, queryLeave));
      await dispatch(get_payroll_instance(activeOuid, query));
    };
    fetchData(dispatch);
    // fetchPayroll(dispatch, selectedDate);
  }, [dispatch]);


  return (
    <div>
      <HeaderInner pageTitle="Staff Profile" />
      <div className="HrmAppPage container--narrow">
        <div className="">
          <div className="profile_top">
            <div className="profile_left">
              <div className="profile_img">
                {/* <img src={profimg} /> */}
                <Avatar
                  className={classes.avatar}
                  style={{
                    backgroundColor: "#214B87",
                  }}
                >
                  {staffdetails.name && staffdetails.name.charAt(0)}
                </Avatar>
              </div>
              <div className="profile_details">
                <h6 className="profile_name">{staffdetails.name}</h6>
                {/* <div className="profile_company_name">
                  {leaddetails.companyName}ghtdfh
                </div> */}
              </div>
            </div>
            <div className="profile_right">
              <button
                className="option_btn"
                onClick={() => setIsDrawerOPen(true)}
              >
                <MoreVertIcon />
              </button>
              <Drawer
                className="edit_option_btn_drawer"
                anchor="bottom"
                open={isDrawerOpen}
                onClose={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option_head">options</div>
                <ul className="edit_option_tools_wrap">
                  <li
                    className="edit_option_tools_item"
                    onClick={() => {
                      setIsDrawerOPen(false);
                    }}
                  >
                    <EditIcon
                    //   onClick={() =>
                    //     history.push(
                    //       `/${activeOuid}/edit-lead/${leaddetails.id}`
                    //     )
                    //   }
                    />
                    <span className="edit_option_tools_item_text">Edit</span>
                  </li>
                </ul>
              </Drawer>
            </div>
          </div>
          <div>
            <AppBar
              position="static"
              color="default"
              className={classes.tabHead}
            >
              <Tabs
                className={classes.tabs}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  className={classes.tab}
                  label="Attendance"
                  {...a11yProps(0)}
                />
                <Tab className={classes.tab} label="Leaves" {...a11yProps(1)} />
                <Tab
                  className={classes.tab}
                  label="Payroll"
                  {...a11yProps(2)}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                className={classes.tabpanel}
              >
                <AttendanceLog />
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                dir={theme.direction}
                className={classes.tabpanel}
              >
                {/* Leaves */}
                {leaves.length !== 0
                  ? renderLeaveList(leaves)
                  : "No leaves found..."}
              </TabPanel>
              <TabPanel
                value={value}
                index={2}
                dir={theme.direction}
                className={classes.tabpanel}
              >
                <div className="staff_profile_datepicker_wrap">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      views={["month", "year"]}
                      label="Month"
                      helperText=""
                      value={selectedDate}
                      onChange={onDateChange}
                      variant="dialog"
                      inputVariant="outlined"
                      maxDate={new Date()}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                {payrollinstanceprofile.length > 0 && (payrollinstanceprofile != [] ||
                payrollinstanceprofile != {}) ? (
                  <PaySlipNew payroll={payrollinstanceprofile}/>
                ) : (
                  <>No payslip for the month</>
                )}
              </TabPanel>
            </SwipeableViews>
          </div>
          {/* </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
