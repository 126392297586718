import {
    CREATE_OR_UPDATE_CONFIGS_REQUESTED,
    CREATE_OR_UPDATE_CONFIGS_SUCCESS,
    CREATE_OR_UPDATE_CONFIGS_ERRORED,
    GET_ALL_ACTIVE_CONFIGS_REQUESTED,
    GET_ALL_ACTIVE_CONFIGS_SUCCESS,
    GET_ALL_ACTIVE_CONFIGS_ERRORED,
    GET_ALL_DEPLOYED_CONFIGS_REQUESTED,
    GET_ALL_DEPLOYED_CONFIGS_SUCCESS,
    GET_ALL_DEPLOYED_CONFIGS_ERRORED,
    SET_ACTIVE_OUID_CONFIGS_REQUESTED,
    SET_ACTIVE_OUID_CONFIGS_SUCCESS,
    SET_ACTIVE_OUID_CONFIGS_ERRORED,
} from '../constant/action-types';
import axios from 'axios';
import { showSnackbar } from './action-snackbar';

import configureStore from '../store/configureStore';

const store = configureStore();


export const create_or_update_configs_requested = () => ({
    type: CREATE_OR_UPDATE_CONFIGS_REQUESTED,
});

export const create_or_update_configs_success = () => ({
    type: CREATE_OR_UPDATE_CONFIGS_SUCCESS,
});

export const create_or_update_configs_errored = (error) => ({
    type: CREATE_OR_UPDATE_CONFIGS_ERRORED,
    error,
});



export const create_or_update_configs = (activeOuid, identifier, payload, action) => async (dispatch) => {
    dispatch(create_or_update_configs_requested());
    console.log(`create_or_update_configs`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        let url;
        if(action === 'create'){
            url = `${apiEndpoint}/config/${identifier}?createArtifact=true`;
        }else if(action === 'update'){
            url = `${apiEndpoint}/config/${identifier}?updateArtifact=true`;
        }
        // console.log(`jwt : ${jwt}`);
        // console.log(`url : ${url}`);
        const method = 'put';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': activeOuid
            },
        };
        return axios[method](
            url,
            payload,
            axiosHeader,
        )
            .then((response) => {
                console.log("Create Or Update Configs Success");
                if(action === 'create'){
                    dispatch(showSnackbar('Config Activated Successfully', 'success'));
                }else if(action === 'update'){
                    dispatch(showSnackbar('Config Updated Successfully', 'success'));
                }
                
                dispatch(create_or_update_configs_success(response.data));
            })
            .catch((err) => {
                console.log('Create Or Update Configs Failed');
                // console.error('ErrorLog : ', JSON.stringify(err));
                if (err.response) {
                    var data = err.response.data;
                    dispatch(showSnackbar('Create Or Update Configs Failed', 'error'));
                } else {
                    dispatch(showSnackbar('Create Or Update Configs Failed', 'error'));
                }
                dispatch(create_or_update_configs_errored(err));
            });
    }
    return null;
};


export const get_all_active_configs_requested = () => ({
    type: GET_ALL_ACTIVE_CONFIGS_REQUESTED,
});

export const get_all_active_configs_success = (payload) => ({
    type: GET_ALL_ACTIVE_CONFIGS_SUCCESS,
    payload
});

export const get_all_active_configs_errored = (error) => ({
    type: GET_ALL_ACTIVE_CONFIGS_ERRORED,
    error,
});



export const get_all_active_configs = (activeOuid) => async (dispatch) => {
    dispatch(get_all_active_configs_requested());
    console.log(`get_all_active_configs`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        let url = `${apiEndpoint}/config`;
        // console.log(`jwt : ${jwt}`);
        // console.log(`url : ${url}`);
        const method = 'get';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': activeOuid
            },
        };
        return axios[method](
            url,
            axiosHeader,
        )
            .then((response) => {
                console.log("Get All Active Configs Success");
                // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
                dispatch(get_all_active_configs_success(response.data));
            })
            .catch((err) => {
                console.log('Get All Active Configs Failed');
                // console.error('ErrorLog : ', JSON.stringify(err));
                if (err.response) {
                    var data = err.response.data;
                    dispatch(showSnackbar(data.error, 'error'));
                } else {
                    dispatch(showSnackbar('Get All Active Configs Failed', 'error'));
                }
                dispatch(get_all_active_configs_errored(err));
            });
    }
    return null;
};




export const set_active_ouid_configs_requested = () => ({
    type: SET_ACTIVE_OUID_CONFIGS_REQUESTED,
});

export const set_active_ouid_configs_success = (payload) => ({
    type: SET_ACTIVE_OUID_CONFIGS_SUCCESS,
    payload
});

export const set_active_ouid_configs_errored = (error) => ({
    type: SET_ACTIVE_OUID_CONFIGS_ERRORED,
    error,
});




export const setActiveOiudConfigs = (activeOuid) => async (dispatch) => {
    console.log(`setActiveOiudConfigs`);
    dispatch(set_active_ouid_configs_requested());
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        let url = `${apiEndpoint}/config`;
        // console.log(`jwt : ${jwt}`);
        // console.log(`url : ${url}`);
        const method = 'get';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': activeOuid
            },
        };
        return axios[method](
            url,
            axiosHeader,
        )
        .then((response) => {
            console.log("Set Active Ouid Configs Success");
            // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
            dispatch(set_active_ouid_configs_success(response.data));
        })
        .catch((err) => {
            console.log('Set Active Ouid Configs Failed');
            // console.error('ErrorLog : ', JSON.stringify(err));
            if (err.response) {
                var data = err.response.data;
                dispatch(showSnackbar(data.error, 'error'));
            } else {
                dispatch(showSnackbar('Set Active Ouid Configs Failed', 'error'));
            }
            dispatch(set_active_ouid_configs_errored(err));
        });
    }
    return null;
};



export const get_all_deployed_configs_requested = () => ({
    type: GET_ALL_DEPLOYED_CONFIGS_REQUESTED,
});

export const get_all_deployed_configs_success = (payload) => ({
    type: GET_ALL_DEPLOYED_CONFIGS_SUCCESS,
    payload
});

export const get_all_deployed_configs_errored = (error) => ({
    type: GET_ALL_DEPLOYED_CONFIGS_ERRORED,
    error,
});



export const get_all_deployed_configs = (activeOuid) => async (dispatch) => {
    dispatch(get_all_deployed_configs_requested());
    console.log(`get_all_deployed_configs`);
    const {
        apiEndpoint,
        credentials,
    } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
        const jwt = `${credentials.idToken.jwtToken}`;
        let url = `${apiEndpoint}/config?deployArtifact=true`;
        // console.log(`jwt : ${jwt}`);
        // console.log(`url : ${url}`);
        const method = 'get';
        const axiosHeader = {
            headers: {
                Authorization: jwt,
                'x-ouid': activeOuid
            },
        };
        return axios[method](
            url,
            axiosHeader,
        )
            .then((response) => {
                console.log("Get All Deployed Configs Success");
                // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
                dispatch(get_all_deployed_configs_success(response.data));
            })
            .catch((err) => {
                console.log('Get All Deployed Configs Failed');
                // console.error('ErrorLog : ', JSON.stringify(err));
                if (err.response) {
                    var data = err.response.data;
                    dispatch(showSnackbar(data.error, 'error'));
                } else {
                    dispatch(showSnackbar('Get All Deployed Configs Failed', 'error'));
                }
                dispatch(get_all_deployed_configs_errored(err));
            });
    }
    return null;
};






